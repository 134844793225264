.defaultGames
  align-items: flex-start
  position: relative
  padding: 0 24px
  & .ant-space-item
    width: 130px
    & .separator
      position: relative
      width: 100%
      height: 45px
      display: flex
      align-items: flex-end
      &:before
        content: ""
        width: 6px
        height: 1px
        position: absolute
        bottom: 0
        background-color: $icon-hover-color
        left: 0
        right: 0
        margin: auto
    &:nth-child(1)
      margin-right: 24px
    &:nth-child(2),
    &:nth-child(4)
      width: calc((100% - 124px) / 2)
    &:nth-child(3)
      width: 24px
    &:last-child
      margin-left: 8px
      width: 24px
  & .iconBox
    display: flex
    align-items: flex-end
    opacity: 0.7
    &:hover
      color: #002766
      opacity: 1
    &:active
      color: #1890ff
    &.disabled
      opacity: 0.5
      pointer-events: none
  &.firstChild .iconBox
    height: 49px
  & .iconBox
      height: 23px
  &:not(&.firstChild) .separator
    height: 19px
.defaultGamesItem
  background: rgb(204 204 204 / 25%)
  padding: 24px
  margin-bottom: 12px
.gameTemplateCurrencySelect
  font: 14px/22px "Red Hat Display Regular"
  color: $project-global-color
  width: auto
  & .ant-select-arrow
    transform: rotate(270deg)
  &.ant-select-open
    & .ant-select-arrow
      transform: rotate(0deg)
  & .ant-select-selector
    & .ant-select-selection-item
      display: flex
      align-items: center
      justify-content: center
  & .icon
    width: 18px
    height: 18px
    margin-right: 8px

