.formHorizontal
    display: flex
    align-items: center
    justify-content: flex-start
    flex-direction: row !important
    &.range-time-picker
        & .ant-col.ant-form-item-label
            min-width: 70px
            margin-right: 3px
            text-align: left
            font: 300 12px/22px "SF Pro Display Regular"
            letter-spacing: 0
            color: $project-global-color
    & .ant-col.ant-form-item-label
        padding: 0
        margin-right: 16px
    & .ant-form-item-control
        flex-grow: unset
.requiredMsg
    text-align: left
    font: 14px/22px "SF Pro Display Regular"
    letter-spacing: 0
    color: $project-global-color
    &.error
        color: #FF8659

.verticalLabel
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-direction: column

.text-right
    text-align: right !important