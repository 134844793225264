.searchBar
  &--row
    max-height: 90px
    display: flex
    align-items: stretch
    justify-content: flex-start
    flex-wrap: nowrap
    flex-direction: row
    width: 100%
    transition: max-height 0.7s ease-in-out
    @media only screen and (max-width: 900px)
      flex-wrap: wrap
      max-height: 180px
    &.static
      max-height: 1500px
      flex-wrap: wrap
      & .searchBar--col
        display: block
        width: calc(100% / 5 - 16px)
        @media (max-width: 1700px) and (min-width: 1200px)
          width: calc(100% / 4 - 16px)
        @media (max-width: 1200px) and (min-width: 900px)
          width: calc((100% / 2)  - 16px)
        @media (max-width: 900px) and (min-width: 700px)
          width: calc(100%  - 16px)
        @media (max-width: 700px)
          width: 100%
    &.show
      transition: max-height 0.7s ease-in-out
      max-height: 1500px
      flex-wrap: wrap
      & .searchBar--col
        display: block
        width: calc(100% / 5 - 16px)
        @media only screen and (max-width: 1700px) and (min-width: 1200px)
          width: calc(100% / 3 - 16px)
        @media only screen and (max-width: 1200px) and (min-width: 900px)
          width: calc(100% / 2  - 16px)
        @media only screen and (max-width: 900px) and (min-width: 700px)
          width: calc(100%  - 16px)
        @media only screen and (max-width: 700px)
          width: 100%
        &.btnBox
          flex: 1
          width: calc(100% / 3 - 16px)
        & .ant-picker.ant-picker-range
          width: 100%
    & .searchBar--col
      width: calc(100% / 5 - 16px)
      margin: 8px
      display: none
      @media only screen and (min-width: 1500px)
        display: none
        @for $i from 1 through 4
          &:nth-child(#{$i})
            display: block

      @media only screen and (max-width: 1500px) and (min-width: 1200px)
        width: calc(100% / 4 - 16px)
        display: none
        @for $i from 1 through 3
          &:nth-child(#{$i})
            display: block

      @media only screen and (max-width: 1200px) and (min-width: 900px)
        width: calc(100% /3  - 16px)
        display: none
        @for $i from 1 through 1
          &:nth-child(#{$i})
            display: block

      @media only screen and (max-width: 900px)
        width: calc(100% /2  - 16px)
        display: none
        @for $i from 1 through 2
          &:nth-child(#{$i})
            display: block

      & .ant-row.ant-form-item
        margin-bottom: 0
        & .ant-col.ant-form-item-label
          padding-bottom: 5px
      &.btnBox
        display: flex
        align-items: flex-end
        justify-content: flex-start
        height: 60px
        margin: 8px
        & button
          margin: 0 10px 0 0
      & input
        cursor: text
        &[type="text"],&[type="number"]
          cursor: text
  &.csv
    .searchBar--row
      & .searchBar--col
        &.btnBox
          display: flex
          align-items: flex-end
          justify-content: space-between
          height: 60px
          margin-right: 0
          width: calc(100% / 5 - 16px)
          max-width: 300px
          @media only screen and (max-width: 1700px) and (min-width: 1200px)
            width: calc(100% / 3 - 16px)
          @media only screen and (max-width: 1200px) and (min-width: 900px)
            width: calc(100% / 2  - 16px)
          @media only screen and (max-width: 900px) and (min-width: 700px)
            width: 100%
          & .filterPart
            display: flex
            align-items: flex-end
            justify-content: flex-start
            & button
              margin: 0 5px 0 0
        & button
          margin: 0
